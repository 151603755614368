import React from 'react';

const Spinner = () => {
  const spinnerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it appears on top
  };

  const circleStyle = {
    border: '4px solid #f3f3f3', // Light gray border
    borderTop: '4px solid #3498db', // Blue color
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  return (
    <div style={spinnerStyle}>
      <div style={circleStyle}></div>
    </div>
  );
};

export default Spinner;
