import React, { useState, useEffect } from 'react';

const UpdateTransactionModal = ({ transaction, paymentChange, setPaymentChange, onUpdate, onClose }) => {

        const [transactionData, setTransactionData] = useState(transaction);
        const [cashChange, setCashChange] = useState(paymentChange);

        function formatDate(d) {
          const dt = new Date(d);
          const month = dt.getMonth() + 1;
          const year = dt.getFullYear();
          const date = dt.getDate();
          return `${year}-${month}-${date}`;
        }

        function formatTime(t) {
          const dt = new Date(t);
          let hours = dt.getHours();
          const minutes = dt.getMinutes().toString().padStart(2, '0');
          const ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12 || 12; // Convert to 12-hour format
          return `${hours}:${minutes} ${ampm}`;
        };
      
        const now = new Date();
        //const currentDate = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        const utcNow = new Date(now.toISOString()); // Convert to UTC
        const utcPlus8 = new Date(utcNow.getTime() + (8 * 60 * 60 * 1000)); // Adjust to UTC+8
    
        useEffect(() => {
            setTransactionData(transaction);
        }, [transaction]);

        useEffect(() => {
            setCashChange(paymentChange);
        }, [paymentChange]);

        const computePaymentChange = (e) => {
            const cash = parseFloat(e.target.value);
            if (!isNaN(cash)) {
                const newCashChange = cash - transactionData.total;
                setCashChange(newCashChange);
                setPaymentChange(newCashChange); // Directly update parent state
            }
        };
        
        const handleConfirmation = async (e) => {
          e.preventDefault();

          const thisTransaction = {
            ...transactionData,
            date_paid: utcPlus8,
          };
  
          onUpdate(thisTransaction);
        };


        return (
          <div className="fixed inset-0 mt-[-40%] flex items-center justify-center bg-[#424242] bg-opacity-90 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in z-50">
            <div className="max-w-sm w-[90%] bg-[#F5F5F5] bg-opacity-95 rounded-lg shadow-lg flex flex-col justify-center items-center overflow-hidden">



              <div style={{textShadow: '-1px -1px 1px gray'}} className="text-center text-2xl font-bold  py-4 bg-[#229799] text-white w-full ">Payment Confirmation</div>
              <hr className="border-1 w-full border-black"/>
              <div className='border-2 rounded-md border-black w-[90%] m-4 px-3 py-4'>
                <div className='grid grid-cols-2 font-normal text-sm m-auto gap-1'>
                  
                  <div className='text-end px-2 py-1'>Date/Time: </div>
                  <div className='text-start px-2 py-1 border border-black rounded bg-white'>{formatDate(transactionData.date_time_accepted)} {formatTime(transactionData.date_time_accepted)}</div>
                  
                  <div className='text-end px-2 py-1'>Name: </div>
                  <div className='text-start px-2 py-1 font-bold border border-black rounded bg-white'>{transactionData.name}</div>
                  
                  <div className='text-end px-2 py-1'>Service/Loads: </div>
                  <div className='text-start px-2 py-1 border border-black rounded bg-white'>{transactionData.service} ({transactionData.loads})</div>
                  
                  <hr className='col-span-2 border-black my-2'/>
                  
                  <div className='text-end px-1 py-2'>Amount Recievable: </div>
                  <div className='text-end mb-2 px-3 py-2 font-bold border border-black rounded bg-white'>{transactionData.total.toFixed(2)}</div>

                  <div className='text-end px-1 py-1'>Cash:</div>
                  <input type="number" placeholder='Input Cash' onChange={computePaymentChange} className="border border-black bg-white rounded text-right pr-3 py-2 font-bold placeholder:text-gray-350" />

                  <hr className='col-span-2 border-black my-2'/>

                  <div className='text-end px-2 py-2'>Change: </div>
                  <div className='text-end px-3 py-2 border border-black rounded bg-white'>{cashChange < 0 ? <b className='text-red-600'>({Math.abs(cashChange).toFixed(2)})</b> : <b>{cashChange.toFixed(2)}</b> }</div>

                </div>
              </div>

              <hr className="w-full border-black"/>

              <div className="modalBtnSection flex justify-end space-x-2 p-1">

                  <button className="bg-[#D32F2F] text-white px-4 py-2 rounded-md" onClick={onClose}>
                    Cancel
                  </button>

                  <button className={`px-4 py-2 rounded-md ${cashChange < 0 ? 'text-gray-400 border border-gray-400 disabled' : 'text-white bg-[#2E7D32]'}`} onClick={handleConfirmation} disabled={cashChange < 0}>
                    Confirm
                  </button>
              
              </div>

            </div>  
          </div>

        );
      };

export default UpdateTransactionModal;
