import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddNewTransactionModal = ({ setLoading, onAdd, onClose }) => {

    const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const now = new Date();
    const utcNow = new Date(now.toISOString()); // Convert to UTC
    const utcPlus8 = new Date(utcNow.getTime() + (8 * 60 * 60 * 1000)); // Adjust to UTC+8

    const [newTransactionData, setNewTransactionData] = useState({
        date_time_accepted: utcPlus8,
        date_paid: null,
        customer_id: null,
        service: "",
        loads: 0,
        total: 0,
        status: null
    });

    const [newCustomerData, setNewCustomerData] = useState({
        id: null,
        name: ""
    });

    const [allCustomers, setAllCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isCustomerExist, setIsCustomerExist] = useState(false);

    // Function to capitalize the first letter of the string
    const capitalizeFirstLetter = (str) => {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    // Fetch all customers on mount
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`${backendHostName}/customers/fetchallcustomers.php`);
                setAllCustomers(response.data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    // Filter customers based on name input
    useEffect(() => {
      if (newCustomerData.name) {
        const filtered = allCustomers.filter((customer) =>
          customer.name.toLowerCase().startsWith(newCustomerData.name.toLowerCase())
        );
        setFilteredCustomers(filtered);  // Update filtered customers state
      } else {
        setFilteredCustomers([]);  // Clear the filtered list if input is empty
      }
    }, [newCustomerData.name, allCustomers]);

    // Setting Fixed Amount
    useEffect(() => {
        let fixedAmount = 0;
        if (newTransactionData.service !== "Special") {
            switch (newTransactionData.service) {
                case "Wash Only":
                    fixedAmount = 70 * newTransactionData.loads;
                    break;
                case "Dry Only":
                    fixedAmount = 90 * newTransactionData.loads;
                    break;
                case "Full Service":
                    fixedAmount = 175 * newTransactionData.loads;
                    break;
                default:
                    fixedAmount = 0;
                    break;
            }

            // Update the total amount only if the service is not "Special"
            setTotalAmount(fixedAmount);
            setNewTransactionData((prevData) => ({
                ...prevData,
                total: newTransactionData.service === "Special" ? prevData.total : fixedAmount
            }));
        }
    }, [newTransactionData.loads, newTransactionData.service]);

    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let customerId = null;

            // If customer doesnt exist, insert new customer
            if (!isCustomerExist) {
                const newCustomer = await axios.post(`${backendHostName}/customers/insertnewcustomer.php`, { name: newCustomerData.name });
                customerId = newCustomer.data.id;
            } else {customerId = newCustomerData.id}

            // Prepare transaction data
            const thisTransaction = {
                ...newTransactionData,
                customer_id: customerId,
                total: newTransactionData.service === "Special" ? newTransactionData.total : totalAmount  // Use custom total for "Special"
            };

            // Call the parent function to add the transaction
            onAdd(thisTransaction);
        } catch (error) {
            console.error("Error while saving transaction:", error);
        }
    };

    const handleCustomerSelect = (customer) => {
      setNewCustomerData({ id: customer.id, name: customer.name });
        setTimeout(() => {
          setFilteredCustomers([]); // Ensure dropdown disappears immediately after selection
      }, 10);
      setIsCustomerExist(true);
    };

    return (
        <div className="background mt-[-40%] fixed inset-0 flex items-center justify-center bg-[#424242] bg-opacity-90 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in z-50">
            <div className="container max-w-sm w-[90%] bg-[#F5F5F5] bg-opacity-95 z-10 rounded-lg -6 shadow-lg overflow-hidden">
                <div style={{ textShadow: '-1px -1px 1px gray' }} className="title bg-[#229799] text-white px-2 py-4 text-center font-bold font-sans text-2xl ">New Transaction</div>
                <hr className="border-1 w-full border-black" />
                <div className="text-left text-1xl ml-5 mt-2 mb-0 font-bold">{now.toLocaleDateString('en-US', options)}</div>

                <form onSubmit={handleSave} className="space-y-1 py-1">
                    <div className="w-[90%] mx-auto mb-4 ">

                        {/* Customer Name Input */}
                        <div>
                            <label className="block text-black pb-1">Customer Name:</label>
                            <input
                                type="text"
                                value={capitalizeFirstLetter(newCustomerData.name)}
                                placeholder="Input name"
                                onChange={(e) => setNewCustomerData({ ...newCustomerData, name: e.target.value })}
                                required
                                className="w-full rounded-md border bg-white border-black py-1 px-4 text-black font-bold focus:outline-none focus:ring-2 focus:ring-black"
                            />
                            {filteredCustomers.length > 0 && (
                                <div className="absolute mt-1 w-[165px] bg-white border-2 border-black rounded-md max-h-48 overflow-y-auto">
                                    {filteredCustomers.map((customer) => (
                                        <div
                                            key={customer.id}
                                            onClick={() => handleCustomerSelect(customer)} // Select customer on click
                                            className="cursor-pointer py-2 px-4 hover:bg-[#229799] hover:text-white shadow-md"
                                        >
                                            {customer.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

 
                        {/* Service Selection Inputs */}
                        <label className="block text-gray">Select Service:</label>
                        
                        <div className="grid gap-1 grid-cols-4 ">
                          {['Wash Only', 'Dry Only', 'Full Service', 'Special'].map((service, index) => (
                            <div key={index} className="flex justify-center items-center border rounded-md bg-white text-[14px] shadow-md">
                              <input
                                type="radio"
                                name="Service"
                                value={service}
                                id={service}
                                onChange={(e) => setNewTransactionData({ ...newTransactionData, service: e.target.value, total:0 })
                                }
                                required
                                className="sr-only "
                              />
                              <label
                                htmlFor={service}
                                className={`cursor-pointer w-full h-[70px] text-center align-middle p-3 rounded-md text-black font-semibold border-2 border-black 
                                  ${newTransactionData.service === service ? 'bg-[#229799] text-white' : ''} 
                                  hover:bg-[#229799] transition-all duration-300`}
                              >
                                {service}
                              </label>
                            </div>
                          ))}
                        </div>

                        {/* Loads and Total Inputs */}
                        <div className="LoadsTotals flex space-x-4">
                          <div className="w-1/2">
                            <label className="block text-gray pb-1">Loads:</label>
                            <input
                              type="number"
                              value={newTransactionData.loads === 0 ? "" : newTransactionData.loads}  // Display empty string if value is 0
                              placeholder="Input Load"
                              onChange={(e) => {
                                const loadValue = e.target.value;

                                // Only update the state if the input value is numeric or empty (backspace is allowed)
                                if (loadValue === "" || /^[0-9]*$/.test(loadValue)) {
                                  setNewTransactionData({
                                    ...newTransactionData,
                                    loads: loadValue === "" ? "" : Math.max(1, parseInt(loadValue, 10)), // Ensure it's >= 1
                                  });
                                }
                              }}
                              onKeyDown={(e) => {
                                // Prevent non-numeric input, except for backspace (keyCode 8)
                                if (["e", "+", "-", ".", ","].includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              min="1"  // Prevents negative values (though it won't prevent input in "text" type, but this adds a semantic layer)
                              required
                              className="w-full bg-white rounded-md border border-black py-1.5 px-4 text-black font-bold focus:outline-none focus:ring-2 focus:ring-black text-right"
                            />
                          </div>
                          <div className="w-1/2">
                            <label className="block text-gray pb-1">Total:</label>
                            <input
                              type="number" // Keep the input type as "number"
                              value={newTransactionData.service === "Special" ? newTransactionData.total || "" : totalAmount}
                              placeholder="Input amount"
                              onChange={(e) => {
                                  if (newTransactionData.service === "Special") {
                                      // Capture the updated value
                                      const updatedTotal = e.target.value;
                                      //const filteredValue = updatedTotal.replace(/[e+-]/g, ''); // This regex removes 'e', '+' and '-'

                                      // Allow empty string, otherwise only allow numeric values
                                      if (updatedTotal === "" || /^[0-9]*$/.test(updatedTotal)) {
                                          setNewTransactionData({
                                              ...newTransactionData,
                                              total: updatedTotal,
                                          });
                                      }
                                  }
                              }}
                              onKeyDown={(e) => {
                                // Prevent non-numeric input, except for backspace (keyCode 8)
                                if (["e", "+", "-", ".", ","].includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              min="1" // Prevents negative values
                              readOnly={newTransactionData.service !== "Special"} // Disables input for non-"Special" services
                              required
                              className={`w-full rounded-md border border-black py-1.5 px-4 text-black font-bold focus:outline-none focus:ring-2 focus:ring-black text-right 
                                  ${newTransactionData.service !== "Special" ? 'bg-[#F5F5F5]' : 'bg-white'}`}
                          />
                          </div>
                        </div>

                        {/* Status Inputs */}
                        <label className="block text-gray">Select Payment Status:</label>
                        
                          <div className="flex flex-1 justify-center w-full gap-4">

                          <input
                            type="radio"
                            id="unpaid"
                            name="payment-status"
                            className="sr-only"  // Hide the radio button but keep it functional
                            checked={newTransactionData.status === 0}  // Check if status is 0 (unpaid)
                            onChange={() => setNewTransactionData({ ...newTransactionData, date_paid: null, status: 0 })}
                          />
                          <label
                            htmlFor="unpaid"
                            className={`cursor-pointer py-4 w-full text-center rounded-md shadow-md border-2 border-black font-semibold 
                              ${newTransactionData.status === 0 ? "bg-[#229799] text-white" : "bg-white text-black"} 
                              hover:bg-[#229799] hover:text-white transition-all duration-300`}
                          >
                            Unpaid
                          </label>

                          {/* Paid Option */}
                          <input
                            type="radio"
                            id="paid"
                            name="payment-status"
                            className="sr-only"  // Hide the radio button but keep it functional
                            checked={newTransactionData.status === 1}  // Check if status is 1 (paid)
                            onChange={() => setNewTransactionData({ ...newTransactionData, date_paid: new Date(), status: 1 })}
                          />
                          <label
                            htmlFor="paid"
                            className={`cursor-pointer py-4 w-full text-center rounded-md shadow-md border-2 border-black font-semibold 
                              ${newTransactionData.status === 1 ? "bg-[#229799] text-white" : "bg-white text-black"} 
                              hover:bg-[#229799] hover:text-white transition-all duration-300`}
                          >
                            Paid
                          </label>
                                
                          </div>

                    </div>

                    <hr className="w-full border-black" />

                    {/* Modal Buttons */}
                    <div className="flex justify-center space-x-4 p-4">
                        <button type="button" onClick={onClose} className="bg-[#D32F2F] hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-md">
                            Close
                        </button>
                        <button type="submit" className="bg-[#2E7D32] hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-md">
                            Save
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default AddNewTransactionModal;
