import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { MD5 } from 'crypto-js';
//import laundryImage from '../Pictures/LaundryLoginPage.jpg';
import laundryImage from '../Pictures/water1.jpg';
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/solid'
import { useAuthentication } from './components/authentication';
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  //const { isAuthenticated, setIsnpmAuthenticated, setWhoIsLoggedIn, setIsAdmin } = useAuthentication();
  const { user, setUser, settings, setSettings } = useAuthentication();

  const formatDate = (d) => {
    if (!d) return ''; // Handle invalid dates
    const dt = new Date(d);
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  };

  useEffect(() => {
    // If the user is authenticated, redirect to dashboard page
    if (user.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [user.isAuthenticated, navigate]); // Only rerun when authentication status changes

  const isAfter6AM = () => {
    const currentTime = new Date(); // Get the current time
    const currentHour = currentTime.getHours(); // Get the current hour (0-23)
    
    return currentHour >= 6; // Check if the current hour is greater than or equal to 7
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resAuthenticated = await axios.post(`${backendHostName}/authenticate.php`, {
        username: username,
        password: MD5(password).toString()
      });
  
      if (resAuthenticated.data && !resAuthenticated.data.msg) {
        const newUser = { 
          isAuthenticated: true, 
          whoIsLoggedIn: resAuthenticated.data.username, 
          isAdmin: resAuthenticated.data.privilege 
        };
  
        setUser(newUser); // This will update sessionStorage automatically
  
        const res = await axios.get(`${backendHostName}/settings/getsettings.php`);
        //console.log(res.data);
        
        const today = new Date();
        const utcNow = new Date(today.toISOString()); // Convert to UTC
        const utcPlus8 = new Date(utcNow.getTime() + (8 * 60 * 60 * 1000)); // Adjust to UTC+8
  
        const isDateOfBussinessYesterday = (dateYesterday, dateToday) => {
          // Check if dateYesterday is not null
          if (!dateYesterday) {
            return false;
          }
        
          // Ensure dateYesterday is a Date object
          const dateObj = new Date(dateYesterday);
        
          // Check if the conversion was successful
          if (isNaN(dateObj.getTime())) {
            console.error("Invalid date_of_bussiness:", dateYesterday);
            return false;
          }
        
          // Subtract one day from dateToday
          const yesterday = new Date(dateToday);
          yesterday.setDate(yesterday.getDate() - 1);
        
          // Compare the full date (year, month, day)
          return dateObj.getDate() === yesterday.getDate() &&
                 dateObj.getMonth() === yesterday.getMonth() &&
                 dateObj.getFullYear() === yesterday.getFullYear();
        };
        
        console.log(isDateOfBussinessYesterday(res.data.date_of_bussiness, utcPlus8));

        if (res.data.bussiness_open === 0 && isDateOfBussinessYesterday(res.data.date_of_bussiness, utcPlus8) && isAfter6AM()) {
          console.log('true');

          const updatedRes = await axios.put(`${backendHostName}/settings/updatesettings.php`, { 
            bussiness_open: 1, 
            date_of_bussiness: formatDate(utcPlus8) 
          });
  
          setSettings({ bussinessOpen: updatedRes.data.bussiness_open, dateOfBussiness: updatedRes.data.date_of_bussiness });
        } else {
          console.log('false');
          // Ensure date_of_bussiness is a valid Date object before calling getDate() or other methods
          const dob = new Date(res.data.date_of_bussiness);
          
          if (isNaN(dob.getTime())) {
            console.error("Invalid date_of_bussiness:", res.data.date_of_bussiness);
          } else {
            setSettings({ bussinessOpen: res.data.bussiness_open, dateOfBussiness: res.data.date_of_bussiness });
          }
        }
  
        navigate("/Dashboard");
      } else {
        setMessage(resAuthenticated.data.msg);
      }
  
    } catch (err) {
      console.log(err);
    }
  };

  


  return (
    <div className="fixed inset-0 overflow-hidden">
      <img src={laundryImage} alt="Laundry Background" className="object-cover w-full h-[100%] overflow-y-auto" />
      <div className="flex justify-center h-[40%] mt-40 fixed inset-0 z-10">
      <div className="bg-white/30 backdrop-blur-lg rounded-lg w-[80%] max-w-md p-4 shadow-xl">
        <h1 className="text-3xl font-bold text-center">Login</h1>
          {message && <p className='text-red-700 text-center'>{message}</p>}
          <form onSubmit={handleSubmit}>
            <div className="relative mt-3 rounded-md flex justify-center">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => {setUsername(e.target.value); setMessage("");}}
                required
                placeholder="Username"
                className="backdrop-blur-none bg-white/50 block max-w-xs w-[80%] rounded-full border-1 border-black shadow-lg py-1.5 pl-7 pr-20 text-gray-900 font-bold ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
              <div className="pointer-events-none absolute inset-y-0 right-12 flex items-center ">
                <span className="text-gray-500 sm:text-sm">
                  <UserIcon aria-hidden="true" className="h-6 w-6 text-black" />
                </span>
              </div>
            </div>
            <div className="relative mt-5 rounded-md flex justify-center">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value); setMessage("");}}
                required
                placeholder="Password"
                className="backdrop-blur-none bg-white/50 block max-w-xs w-[80%] rounded-full border-1 border-black shadow-lg py-1.5 pl-7 pr-20 text-gray-900 font-bold ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
              <div className="pointer-events-none absolute inset-y-0 right-12 flex items-center ">
                <span className="text-gray-500 sm:text-sm">
                  <LockClosedIcon aria-hidden="true" className="h-6 w-6 text-black " />
                </span>
              </div>
            </div>
            <div className='flex justify-center mt-10'>
              <button 
                className='bg-[#229799] rounded-full py-2 mx-6 w-[80%] border-1 border-black shadow-lg text-white'
                type="submit">Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="dashboard-company-title text-lg text-center mt-[-40px] p-1 z-20">©2024 <label style={{textShadow: "1px 1px 2px black"}} className="text-fuchsia-400 drop-shadow-md">Myla</label><label style={{textShadow: "1px 1px 2px black"}} className="text-indigo-300 drop-shadow-md">bandera</label></div>
    </div>

  );
};


export default LoginPage;
