import React from 'react'
import axios from 'axios';
import MD5 from 'crypto-js/md5';
import { useState } from 'react';


const EmployeeModal = ({closeModal , employeeModalData}) => {
  const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
  const [newUsername, setNewUsername] = useState(employeeModalData.currUsername)
  const [newPassword, setNewPassword] = useState("")
  const [newPrivilege, setNewPrivilege] = useState(employeeModalData.currPrivilege)
  const [inputError, setInputError] = useState("");
  
  const newEmployee = {
      username: newUsername,
      password: MD5(newPassword).toString(),
      privilege: newPrivilege
  };

  const handleSave = async (e) => {
    e.preventDefault()
    setInputError("");

    if (employeeModalData.action === "add"){
      if (!newUsername && !newPassword && !newPrivilege) {
        setInputError("Please input Username and Password.");
        return;
      }else if (!newUsername) {
        setInputError("Please input Username")
        return;
//      }else if (!newPassword) {
//        setInputError ("Please input Password")
//        return;
      }else if (!newPrivilege) {
        setInputError ("Please choose a Privilege")
        return;
      }
    };

    try {
      if (employeeModalData.action === 'add'){

        //await axios.post("http://192.168.1.100:8800/insertnewemployee", newEmployee)
          await axios.post(`${backendHostName}/employees/insertnewemployee.php`, newEmployee);

      } else if (employeeModalData.action === 'edit') {

          //await axios.put(`http://192.168.1.100:8800/updateemployee/${employeeModalData.currId}`, newEmployee);
          await axios.put(`${backendHostName}/employees/updateemployee.php?id=${employeeModalData.currId}`, newEmployee);

      }
      closeModal({isVisible:false, action:"", currId:"", currUsername:"", currPrivilege:""});
      window.location.reload()
    } catch (err)  {
        console.log(err);
    }
  };

/*

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a user object with id, name, and email
    const updatedUser = { id, name, email };

    // Make POST request to update the user
    axios.post('http://localhost/backend/updateUser.php', updatedUser)
      .then(response => {
        setMessage(response.data.message); // Set success or error message
      })
      .catch(error => {
        setMessage('There was an error updating the user');
        console.error(error);
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new user object
    const newUser = { name, email };

    // Make POST request to insert the new user
    axios.post('http://localhost/backend/insertUser.php', newUser)
      .then(response => {
        setMessage(response.data.message); // Set success or error message
        setName('');
        setEmail('');
      })
      .catch(error => {
        setMessage('There was an error inserting the user');
        console.error(error);
      });
  };
*/

  return (
    <div className = "modalBackground">
        <div className = "modalContainer">
            <div className = "modalTitle">New Employee</div>
            <hr/>
            {inputError && <div className="errorMessage" style={{ color: 'red' }}>{inputError}</div>}
            <form>
                <div className="modalInputSection">
                    <div><label />Username: <input className='border border-black rounded' type="text" value = {newUsername} onChange={(e) => {setNewUsername(e.target.value); setInputError("")}} required/></div>
                    <div><label />Password: <div className='text-sm'>{employeeModalData.action === "edit" ? "(If no password change please leave blank)" : ""}</div> <input className='border border-black rounded' type="text" value = {newPassword} onChange={(e) => {setNewPassword(e.target.value); setInputError("")}} /></div>
                    <div>
                        <label />Privilege: 
                        {employeeModalData.action === "add" 
                          ? (<div className='px-2' >
                            <input type="radio" name = "privilege" value="1" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required /><label /> Admin
                            <input className='pl-2' type="radio" name = "privilege" value="0" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required /><label /> User
                            </div>)
                         : (employeeModalData.currPrivilege === 1
                            ? (<div className='px-2' >  
                              <input type="radio" name = "privilege" value="1" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required checked/><label/> Admin
                              <input className='pl-2' type="radio" name = "privilege" value="0" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required /><label /> User
                              </div>)
                            : (<div className='px-2' >
                              <input type="radio" name = "privilege" value="1" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required /><label/> Admin
                              <input className='pl-2' type="radio" name = "privilege" value="0" onChange={(e) => {setNewPrivilege(e.target.value); setInputError("")}} required checked/><label /> User
                              </div>)
                            ) 
                          }
                    </div>
                </div>
                <hr/>
                <div className="modalBtnSection">
                    <button className="modalBtn" type="button" style = {{backgroundColor:"red"}} onClick={() => closeModal({
                      isVisible:false,
                      action:"",
                      currId:"",
                      currUsername:"",
                      currPrivilege:""
                      })}>Close</button>
                    <button className="modalBtn" style = {{backgroundColor:"green"}} onClick={handleSave}>Save</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default EmployeeModal
