import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './components/employeeModal.jsx';
import { useNavigate } from "react-router-dom";
import { useAuthentication } from './components/authentication.jsx';

const Employees = () => {
    const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
    const [employees, setEmployees] = useState([])
    const [openModal,setOpenModal] = useState({
        isVisible:false,
        action:null,
        currId:null,
        currUsername:null,
        currPrivilege:null
    })

    const navigate = useNavigate();
    //const { isAuthenticated, isAdmin } = useAuthentication();
    const { user } = useAuthentication();

    useEffect(() => {
      // If the user is not authenticated and not admin, redirect to login page
      if (!user.isAuthenticated && !user.isAdmin) {
        navigate("/");
      }
    }, [user.isAuthenticated, user.isAdmin, navigate]); // Only rerun when authentication status changes
  
    useEffect(() => {
        const fetchAllEmployees = async () => {
            try{
                //const res = await axios.get("http://192.168.1.100:8800/fetchemployees");
                const res = await axios.get(`${backendHostName}/employees/fetchallemployees.php`);
                setEmployees(res.data);
            }catch(err){
                console.log(err);
            }
        };
        fetchAllEmployees();
    }, []);
/*
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(''); // Store the user ID input

  // Function to fetch user by ID
  const fetchUser = (id) => {
    axios.get(`http://localhost/backend/getUser.php?id=${id}`)
      .then(response => {
        if (response.data && response.data.id) {
          setUser(response.data); // Set the fetched user data
        } else {
          alert('User not found');
        }
      })
      .catch(error => {
        console.error('There was an error fetching the user!', error);
      });
  };

  const [id, setId] = useState(''); // State to hold the user ID to be deleted
  const [message, setMessage] = useState(''); // State to show success or error message

  const handleDelete = (e) => {
    e.preventDefault();

    // Create an object with the user ID to delete
    const userToDelete = { id };

    // Make POST request to delete the user
    axios.post('http://localhost/backend/deleteUser.php', userToDelete)
      .then(response => {
        setMessage(response.data.message); // Set success or error message
      })
      .catch(error => {
        setMessage('There was an error deleting the user');
        console.error(error);
      });
  };

*/



    const handleDelete = async (id) => {
        try {
            //await axios.delete(`http://192.168.1.100:8800/deleteemployee/${id}`);
            await axios.delete(`${backendHostName}/employees/deleteemployee.php?id=${id}`);
            setEmployees(employees.filter(employee => employee.id !== id));  // Remove deleted employee from the list
        } catch (err) {
            console.log(err);
        }
    };

    return ( 
        <>
            <div className="pHead bg-[#229799]">
                <h2 className='text-xl font-bold text-white py-3'>Employees</h2>
                <button className="bg-green-600 text-white font-bold border rounded shadow-md text-l px-2 py-1" onClick={() => setOpenModal({
                    isVisible:true,
                    action:"add",
                    currId:"",
                    currUsername:"",
                    currPrivilege:""
                    })}>
                    + New Employee
                </button>
            </div>
            {/*{message && <p>{message}</p>} {/* Display success or error message */}
            <div className="classEmployees">
                <table>
                    <tbody>
                    
                        <tr className='text-sm'>
                            <th>Employee</th>
                            <th>Privilege</th>
                            <th>Action</th>
                        </tr>
                        {employees.map((employee) => (
                            <tr key={employee.id}>
                                <td>{employee.username}</td>
                                <td>{employee.privilege ? <label className="tagAdmin">Admin</label> : <label className="tagUser">User</label>}</td>
                                <td>
                                    <button className="editBtn p-1 mr-1 bg-green-600 text-white text-sm rounded" onClick={() => setOpenModal({
                                        isVisible:true, 
                                        action:"edit", 
                                        currId:employee.id, 
                                        currUsername:employee.username,
                                        currPrivilege:employee.privilege
                                    })}>Edit</button>
                                    <button className="deleteBtn p-1 ml-1 bg-red-500 text-white text-sm rounded" onClick={() => handleDelete(employee.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                
                    </tbody>
                </table>

                <center><button className='bg-[#229799] px-4 py-2 text-white font-bold rounded-md shadow-md' onClick={() => navigate("/dashboard")}>Back</button></center>

                {openModal.isVisible && 
                    <Modal 
                        closeModal = {() => setOpenModal({ ...openModal, isVisible: false })} 
                        employeeModalData = {openModal} 
                    />}

            </div>
            
        </>
    )
}

export default Employees