import React, { createContext, useContext, useState, useEffect } from "react";
import axios from 'axios';

export const Authentication = createContext();

export const useAuthentication = () => useContext(Authentication);

export const AuthenticationProvider = ({ children }) => {
  const backendHostName = process.env.REACT_APP_BACKEND_HOST_NAME;
  
  // Get the stored user data from sessionStorage (or set default if not available)
  const storedUser = JSON.parse(sessionStorage.getItem('mylabandera_user')) || {
    isAuthenticated: false,
    whoIsLoggedIn: null,
    isAdmin: null,
  };
  const storedSettings = JSON.parse(sessionStorage.getItem('mylabandera_settings')) || {
    bussinessOpen: 0,
    dateOfBussiness: null
  }

  // Initialize state with the stored user data
  const [user, setUser] = useState(storedUser);
  const [settings, setSettings] = useState(storedSettings);

  // Effect hook to update sessionStorage whenever user state changes
  useEffect(() => {
    sessionStorage.setItem('mylabandera_user', JSON.stringify(user));
  }, [user]); // Runs every time the user state changes

  useEffect(() => {
        sessionStorage.setItem('mylabandera_settings', JSON.stringify(settings));
  }, [settings]);

  return (
    <Authentication.Provider
        value={{ 
            user, setUser,
            settings, setSettings
        }}>
        {children}
    </Authentication.Provider>
  );
};

export default Authentication;

