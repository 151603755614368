import {BrowserRouter, Routes, Route} from "react-router-dom";

import LoginPage from "./pages/login.jsx";
import Employees from "./pages/employees.jsx";
import Dashboard from "./pages/dashboard.jsx";
import { AuthenticationProvider } from "./pages/components/authentication.jsx";


function App() {
  return (
    <div className="App">
      <AuthenticationProvider>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/employees" element={<Employees/>} />
          </Routes>
        </BrowserRouter>
      </AuthenticationProvider>
    </div>
  );
}

export default App;
